import A from "../../assets/other/A.png";
import B from "../../assets/other/B.png";
import CS from "../../assets/other/CS.png";
import DA from "../../assets/other/DA.png";
import DEV from "../../assets/other/DEV.png";
import HL from "../../assets/other/HL.png";
import M from "../../assets/other/M.png";
import O from "../../assets/other/O.png";
import SENG from "../../assets/other/SENG.png";
import SO from "../../assets/other/SO.png";
import dataScience from "../../assets/subjects/20402.jpg";
import scienceAndEngin from "../../assets/subjects/female-engineer-in-laboratory-3861449.jpg";
import socialStudies from "../../assets/subjects/happy-ethnic-woman-sitting-at-table-with-laptop-3769021.jpg";
import computerScience from "../../assets/subjects/Image-2.jpg";
import art from "../../assets/subjects/Image-3.jpg";
import business from "../../assets/subjects/Image-4.jpg";
import mathImage from "../../assets/subjects/Image-16.jpg";
import developer from "../../assets/subjects/person-looking-at-phone-and-at-macbook-pro-1181244.jpg";
import health from "../../assets/subjects/photo-of-woman-practicing-yoga-3820320.jpg";

// icons
import affiliate from "../../assets/furimg/affiliate.png";
import dataAnalyst from "../../assets/furimg/dataAnalyst.png";
import ux from "../../assets/furimg/ux.png";
import video from "../../assets/furimg/video.png";
import basic from "../../assets/furimg/basic.png";
import communication from "../../assets/furimg/communication.png";
import contentCreation from "../../assets/furimg/content-creation.png";

export const subjectsData = [
  {
    name: "Computer Science",
    code: "CS",
    content: "",
    image: CS,
    tile: computerScience,
  },
  {
    name: "Business",
    code: "B",
    content: "",
    image: B,
    tile: business,
  },
  {
    name: "Arts & Design",
    code: "A",
    content: "",
    image: A,
    tile: art,
  },
  {
    name: "Data Science",
    code: "DA",
    content: "",
    image: DA,
    tile: dataScience,
  },
  {
    name: "Health & Lifestyle",
    code: "HL",
    content: "",
    image: HL,
    tile: health,
  },
  {
    name: "Science & Engineering",
    code: "SENG",
    content: "",
    image: SENG,
    tile: scienceAndEngin,
  },
  {
    name: "Social Studies",
    code: "SO",
    content: "",
    image: SO,
    tile: socialStudies,
  },
  {
    name: "Developers/Programming",
    code: "DEV",
    content: "",
    image: DEV,
    tile: developer,
  },
  {
    name: "Math",
    code: "M",
    content: "",
    image: M,
    tile: mathImage,
  },
  {
    name: "Others",
    code: "O",
    content: "",
    image: O,
  },
];

export const FRD = [
  {
    name: "Data Visualization: Data Dashboards and Storytelling with Tableau",
    university: "Coventry University",
    provider: "Future Learn",
    icon: dataAnalyst,
    url: "/FutureLearn/f79e010c-4b42-45e4-8a71-0b47142cd953",
  },
  {
    name: "The Complete Guide to Partnership Marketing Course",
    university: "Udemy",
    provider: "Udemy",
    icon: affiliate,
    url: "/Udemy/702ee19c-254b-4791-89d3-d238e1a9f66d",
  },
  {
    name: "How to Create Great Online Content",
    university: "University of Leeds",
    provider: "Future Learn",
    icon: contentCreation,
    url: "/FutureLearn/ec353cf3-935a-4f45-bcd7-3eedda8d4a0b",
  },
  {
    name: "Improve Communication: Speak Smoothly, Clearly & Confidently",
    university: "Udemy",
    provider: "Udemy",
    icon: communication,
    url: "/Udemy/5f3661b8-5174-46c2-829c-cfe3f1a19dd3",
  },
  {
    name: "Introduction to User Experience Design",
    university: "Georgia Institute of Technology",
    provider: "Udemy",
    icon: ux,
    url: "/Udemy/9e6f1d74-47be-44bf-bd9d-a195fdd53c54",
  },
  // {
  //   name: "Video Production: 10 Ways to Instantly Improve Your Videos",
  //   university: "Udemy",
  //   provider: "Udemy",
  //   icon: video,
  //   url: "/Udemy/e96fb258-fd65-4d11-8fe3-1c3f52b80c15",
  // },
  // {
  //   name: "Managing Emotions in Times of Uncertainty & Stress",
  //   university: "Yale University",
  //   provider: "Coursera",
  //   icon: basic,
  //   url: "/Coursera/667ff468-f4ee-41bf-b555-7b0913256202",
  // },
  // {
  //   name: "Complete Google Workspace (G Suite), Beginner - Advanced",
  //   university: "Udemy",
  //   provider: "Udemy",
  //   icon: basic,
  //   url: "/Udemy/90ff991e-98db-4478-8424-ec220657a5b5",
  // },
  // {
  //   name: "Excel for Everyone: Core Foundations",
  //   university: "edX",
  //   provider: "edX",
  //   icon: basic,
  //   url: "/edX/920f55b7-9ce4-446d-a8c6-161717a4aaa6",
  // },
];
