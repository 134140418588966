import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  List,
  IconButton,
  SwipeableDrawer,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
// css
import "../../assets/css/navbar.css";
// images
import logo from "../../assets/images/logo.png";
// icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
// import { logout } from "../../actions/ContextActions";
import { setAlert, setAuth, setCLP } from "../../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// context
// import { LOGIN_MODAL } from '../../../store/Types';
// import Store from '../../../store/Context';

// GA 
import {tracker} from "../../App";

export default function Navbar(props) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const [open, setOpen] = useState(false);
  const history = props.history;
  const [trans, setTrans] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY);
      if (window.scrollY > 100) {
        setTrans(false);
      } else setTrans(true);
    });
  }, []);
  // function for handle login activity
  function handleLog() {
    if (auth.isAuth) history("/profile");
    else {
      history("/login");
    }
  }

  function handleLogout() {
    dispatch(setAuth({ isAuth: false }));
    dispatch(setCLP([]));
    dispatch(
      setAlert({
        open: true,
        variant: "success",
        message: "Logged out successfully !!!",
      })
    );
    history("/");
  }

  return (
    <>
      <Box className={`navContainer ${!trans && "bgWhite"}`}>
        
        {/* LOGO  */}
        <Box className="logo" component={Link} to={"/"} onClick={()=>tracker("Homepage action","Header Icon Press")}>
          <img src={logo} alt="logo" />
        </Box>
        {/* LINKS  */}
        <Box component={List} className="links">
          <Typography variant={"body"} to={"/"} component={Link}>
            Home
          </Typography>
          <Typography variant={"body"} to={"/listing"} component={Link}>
            Courses
          </Typography>
          <Typography variant={"body"} to={"/about"} component={Link}>
            About Us
          </Typography>
          <Typography variant={"body"} to={"/contact"} component={Link}>
            Contact
          </Typography>
          <Typography variant={"body"} to={"https://blogs.classbazaar.com/"} component={Link}>
            Blog
          </Typography>
          <Typography variant={"body"} to={"/career"} component={Link}>
            Career
          </Typography>
        </Box>
        {/* Button */}
        <Box className="button">
          <IconButton size="small" onClick={handleLog}>
            <PersonOutlineOutlinedIcon />
          </IconButton>
          {auth.isAuth && (
            <IconButton
              onClick={handleLogout}
              className="searchBTN"
              size="small"
            >
              <PowerSettingsNewIcon />
            </IconButton>
          )}
        </Box>
        {/* Han Icon */}
        <IconButton className="hamIcon" onClick={() => setOpen(true)}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Hamburger
        open={open}
        setOpen={setOpen}
        history={history}
        dispatch={dispatch}
        state={auth}
        handleLogout={handleLogout}
      />
    </>
  );
}

function Hamburger({ open, setOpen, history, dispatch, state, handleLogout }) {
  // function for handle login activity
  function handleLog() {
    if (state.isAuth) history("/profile");
    else {
      history("/login");
    }
  }

  function LinkBox() {
    return (
      <>
        <IconButton
          onClick={() => setOpen(false)}
          size="small"
          className="closeBTN"
        >
          <CloseIcon />
        </IconButton>
        <Box className="hamLinkContainer">
          <ul className="ul_formate">
            <Link to="/" className="li_formate">
              Home
            </Link>
            <Link to="/listing" className="li_formate">
              Courses
            </Link>
            <Link to="/about" className="li_formate">
              About Us
            </Link>
            <Link to="/contact" className="li_formate">
              Contact
            </Link>
            <Link to={"https://blogs.classbazaar.com/"} className="li_formate">
              Blog
            </Link>
            <Link to="/career" className="li_formate">
              Career
            </Link>
            {state.isAuth && <Link to="/profile" className="li_formate">
              Profile
            </Link>}
            <Button
              variant="contained"
              onClick={() => {
                {
                  state.isAuth ? handleLogout() : handleLog();
                }
              }}
              className="logOutBtn"
            >
              {state.isAuth ? "Logout" : "Login"}
            </Button>
          </ul>
        </Box>
      </>
    );
  }

  return (
    <>
      <SwipeableDrawer anchor={"right"} open={open}>
        <LinkBox />
      </SwipeableDrawer>
    </>
  );
}
