import { Box, Divider, Grid, Typography, Link as A } from "@mui/material";
import React from "react";

// icons
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";

//css
import "../../assets/css/footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  const allObj = {
    Subjects: [
      { link: "/listing/Computer_Science/show", title: "Computer Science" },
      { link: "/listing/Arts_And_Design/show", title: "Arts & Craft" },
      { link: "/listing/Math/show", title: "Math" },
      { link: "/listing/Social_Studies/show", title: "Social Studies" },
    ],
    Provider: [
      { link: "/listing/Udemy/show", title: "Udemy" },
      { link: "/listing/Coursera/show", title: "Coursera" },
      { link: "/listing/edX/show", title: "edX" },
      { link: "/listing/Future_Learn/show", title: "Future Learn" },
    ],
    University: [
      { link: "/listing", title: "Harward University" },
      { link: "/listing", title: "Rice University" },
      { link: "/listing", title: "IIT RORKEE" },
      { link: "/listing", title: "Stanford University" },
    ],
  };
  return (
    <>
      <Grid container className="footerContainer">
        <Grid xs={12} md={3} className="footerItem">
          <Typography className="footerHeading">Browser By Subject </Typography>
          {allObj.Subjects.map((row, index) => (
            <Typography
              variant="caption"
              component={Link}
              to={row.link}
              key={index}
            >
              {row.title}
            </Typography>
          ))}
        </Grid>
        <Grid xs={12} md={3} className="footerItem">
          <Typography className="footerHeading">
            Browser By Provider{" "}
          </Typography>
          {allObj.Provider.map((row, index) => (
            <Typography
              variant="caption"
              component={Link}
              to={row.link}
              key={index}
            >
              {row.title}
            </Typography>
          ))}
        </Grid>
        <Grid xs={12} md={3} className="footerItem">
          <Typography className="footerHeading">
            Browser By University{" "}
          </Typography>
          {allObj.University.map((row, index) => (
            <Typography
              variant="caption"
              component={Link}
              to={row.link}
              key={index}
            >
              {row.title}
            </Typography>
          ))}
        </Grid>
        <Grid xs={12} md={2} className="footerItem">
          <Typography className="footerHeading">Information </Typography>
          <Typography sx={{fontWeight : 700}} variant={"caption"} to={"https://blogs.classbazaar.com/"} component={Link}>
            Blog
          </Typography>
          <Typography  variant="caption">info@classbazaar.com</Typography>
          <Typography variant="caption">
            522, Zest Business Spaces, MG Road, Ghatkopar East, Mumbai 400077{" "}
          </Typography>
          <Box className="social_icons">
            <Box
              component={A}
              target="_blank"
              href={"https://www.facebook.com/classbazaar/"}
            >
              <img src={facebook} alt="social_icon" />
            </Box>
            <Box
              component={A}
              target="_blank"
              href={"https://www.instagram.com/classbazaar/?hl=en"}
            >
              <img src={instagram} alt="social_icon" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider className={"divider"}></Divider>
      {/* // copywriter */}
      <Box className="copywriter">
        <Typography variant="caption">Class Bazaar © 2024</Typography>
      </Box>
      {/* // copywrite ends */}
    </>
  );
}
